import {
  OP_LOGISTIC,
  OP_AGENCY,
  OP_SOURCE,
  OP_USER,
  OP_SIGN,
  OP_INVOICE,
  OP_PUB_DATA,
} from "../../api/config/servicePort"
import UtilOp from "../../services/serviceOp"
import axios from "axios"
const utilOp = UtilOp.getInstance()

//工商代办列表
export const getOperAgenciess = (params?: any) => {
  return utilOp.httpGet(OP_USER + "/business-agencies", params).then((res) => res)
}

//工商代办详情
export const getOperAgenciessDetails = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/business-agencies/${id}`)
}

// 新增工商代办
export const getOperAgenciessAdd = (params: any) => {
  return utilOp.httpPost(OP_USER + "/business-agencies", params)
}

// 编辑工商代办
export const getOperAgenciessEdit = (params: any) => {
  return utilOp.httpPut(OP_USER + `/business-agencies/${params.id}/`, params)
}

//办理区域列表
export const getOperRegions = (params?: any) => {
  return utilOp.httpGet(OP_USER + "/handle-regions", params).then((res) => res)
}
//办理区域详情
export const getOperRegionsDetails = (id?: any) => {
  return utilOp.httpGet(OP_USER + `/handle-regions/${id}`)
}

//id换取文件路径
export const getOrderUploadByPath = async (params: any) => {
  return utilOp.httpPatch(OP_SOURCE + `/oss/endpoint`, params)
}

// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return utilOp
    .httpGet(`${OP_SOURCE}/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}

//建议名称
export const getOperRegionsRecommend = (id: any, params?: any) => {
  return utilOp.httpGet(OP_USER + `/handle-regions/${id}/recommend-name`, params).then((res) => res)
}

// orc身份证
export const getOcrIdCardFront = (url: any) => {
  return utilOp.httpGet(OP_SOURCE + "/ocr/idCard/front", url)
}

//id换取文件路径
export const getOrderUploadFilesByPath = async (params: any) => {
  return utilOp.httpPost(OP_SOURCE + `/oss/post-files`, params)
}
// orc营业执照
export const getOcrBusinessLicense = (url: any) => {
  return utilOp.httpGet(OP_SOURCE + "/ocr/businessLicense", url)
}
// //查询角色
export const getPlatCharactersList = (id: string, params?: any) => {
  return utilOp.httpGet(OP_USER + `/applications/${id}/roles`, params)
}
// 获取所有用户
export const getUserLists = (values?: any) => {
  return utilOp.httpGet(OP_USER + "/users", values).then((res) => res)
}
// 新增开办上传营业执照
export const getBusinessAgenciesEdit = (params: any) => {
  return utilOp.httpPut(OP_USER + `/business-agencies/business-license/${params.id}`, params)
}
// 新增开办完成税务登记
export const getTaxRegistrationEdit = (params: any) => {
  return utilOp.httpPut(OP_USER + `/business-agencies/tax-registration/${params.id}`, params)
}
// 重发委托协议
export const getDelegatedAgreement = (params: any) => {
  return utilOp.httpPut(
    OP_USER + `/business-agencies/resend-delegated-agreement/${params.id}`,
    params
  )
}
// 重发双录
export const getResendDual = (params: any) => {
  return utilOp.httpPut(OP_USER + `/business-agencies/resend-dual/${params.id}`, params)
}
// 新增代办审核
export const getApprovalExamine = (params: any) => {
  return utilOp.httpPost(OP_USER + "/business-agencies/approval", params)
}
// 新增代办修改申请信息
export const getApprovalUpdate = (params: any) => {
  return utilOp.httpPost(OP_USER + "/business-agencies/update", params)
}
// 下载资料
export const getTaxDownloadDetails = (params: any) => {
  return utilOp.httpPost(OP_USER + `/business-agencies/${params.id}/document`)
}
//获取签署链接
export const getContractsSignUrl = (params?: any) => {
  return utilOp.httpGet(OP_SIGN + `/digital-contracts/getSignUrl`, params)
}
export const download = (id?: string, file?: any) => {
  return utilOp
    .httpGetDownload(OP_USER + `/business-agencies/${id}/document`, {}, file)
    .then((res: any) => {
      return res
    })
    .catch((err) => {
      console.log(err, "err")
    })
}
// 创建开通任务
export const getTasksTaxpayer = (params: any) => {
  return utilOp.httpPost(OP_INVOICE + "/taxpayer-tasks", params)
}
// 查询开户行
export const getTissueBankList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/bank", params)
}
// 查询省市区
export const getTissueCityTreeList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/city/tree", params)
}
// 查询联行号
export const getTissueCorrespondentList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/bankcnaps/branch-bank-list", params)
}
// * 获取组织列表
export const getOrgs = (params) => {
  return utilOp.httpGet(OP_USER + "/organizations", params)
}
// 撤销申请
export const RevokeApplication = (params) => {
  return utilOp.httpDeletel(OP_USER + "/business-agencies/" + params.id)
}

// 发送通知
export const sendMs = (id) => {
  return utilOp.httpGet(OP_USER + `/business-agencies/${id}/wx`)
}
