/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import {
  Button,
  Form,
  Space,
  Card,
  Descriptions,
  message,
  Modal,
  Table,
  Input,
  Col,
  Radio,
  Select,
  Popconfirm,
} from "antd"
import React, { memo, useEffect, useState } from "react"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { TagCustomStyle } from "@src/components/TagCustomStyle"
import { radioOptionEnum } from "../../constants"
import { useNavigate, useParams } from "react-router-dom"
import Organizational from "./Organizational"
import NameDeclaration from "./NameDeclaration"
import DetailsInformation from "./DetailsInformation"
import MaterialUploadDetails from "./MaterialUploadDetails"
import OperationLog from "./OperationLog"
import { TurnDownColumns } from "./constants"
import { getFileUrl } from "../../constants"
import {
  getOperAgenciessDetails,
  getUrlByFileId,
  getOperAgenciessEdit,
  getOperAgenciessAdd,
  getPlatCharactersList,
  getBusinessAgenciesEdit,
  getTaxRegistrationEdit,
  getUserLists,
  getDelegatedAgreement,
  getResendDual,
  getApprovalExamine,
  getContractsSignUrl,
  getTasksTaxpayer,
  download,
  RevokeApplication,
  getOrgs,
  sendMs,
} from "../../service"
import {
  businessLicenseStatusEnum,
  taxRegistrationStatusEnum,
  examineStatusEnum,
  getFileUrlDetails,
} from "../../constants"
import "./index.scss"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import copy from "copy-to-clipboard"
import { inputDefaultRules } from "@src/utils/RegExp"
import BusinessLicense from "./components/businessLicense"
import { getFileToBase64 } from "@src/utils/file"
import BankInfo from "./BankInfo"
const defaultProps = {}
type props = {}
const FormItem = Form.Item
const { TextArea } = Input

const prefix = "new-order-detail-page"
const classWithPrefix = (classname: string) => {
  return [prefix, classname].join("-")
}
export type NewPurchaseOrderProps = props & React.HTMLAttributes<HTMLDivElement>
export const ApplicationDetails: React.FC<React.PropsWithChildren<NewPurchaseOrderProps>> = memo(
  () => {
    const [licenseForm] = Form.useForm()
    const [form] = Form.useForm()
    const [taxationForm] = Form.useForm()
    const navigate = useNavigate()
    const history: any = useParams()
    const [detailInfo, setDetailInfo] = useState<any>({})
    const [registrationList, setRegistrationList] = useState<any>([])
    const [isModalOpen, setIsModalOpen] = useState(false) //查看驳回原因
    const [submitOpen, setSubmitOpen] = useState(true) //提交申请
    const [turnDownData, setTurnDownData] = useState<any>([]) //驳回原因data
    const [rejectionOpen, setRejectionOpen] = useState(false) //查看驳回原因
    const [licenseOpen, setLicenseOpen] = useState(false) //上传营业执照
    const [licenseImage, setLicenseImage] = useState<any>([]) //营业执照
    const [taxationOpen, setTaxationOpen] = useState<any>(false) //税务登记
    // const [taxationList, setTaxationList] = useState<any>(false) //税务登记
    const [getTaxMin, setGetTaxMin] = useState<any>([])
    const [videoList, setVideoList] = useState<any>([])
    const [fileImgData, setFileImgData] = useState<any>([])
    const [originalOrgs, setOriginalOrgs] = useState<any>([])
    const access = useAccess()

    const init = async () => {
      console.log("初始化页面", history?.id)
      if (!history?.id) return
      let result: any = null
      const res = await getOrgs({
        searchCount: true,
        size: 9999,
        current: 1,
      })
      if (res.code == 200) {
        result = res.data.records.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      }
      setOriginalOrgs(result)
      const data = await getOperAgenciessDetails(history.id)
      if (data?.code == 200) {
        setDetailInfo(data?.data)
        //筛选驳回原因数据
        let operationLogVosList = data?.data?.operationLogVos?.filter(
          (item) => item.operationType == "审核驳回"
        )
        setTurnDownData(operationLogVosList)
        let newVideo = data?.data?.dualFiles?.map((ie) => {
          return ie.path
        })
        let newRegistration = await handGetUrlByFileId([
          data?.data?.idCardBack,
          data?.data?.idCardFront,
        ])
        setRegistrationList(newRegistration)
        if (newVideo) {
          let newVideoList = await handGetUrlByFileId(newVideo)
          setVideoList(newVideoList)
        }
        // setFileImgData([])
      }
    }
    //获取办税人 运营端角色
    const handGetPlatCharactersList = async (data?: any) => {
      let params = { size: -1, applicationId: 1, roleNames: "办税人", current: 1 }
      const result: any = await getUserLists(params)

      if ((result.code = 200)) {
        const res = result.data.records.map((ie: any) => {
          return {
            label: ie.name,
            value: ie.id,
          }
        })
        setGetTaxMin(res)
      } else {
        message.error("请求失败")
      }
    }
    const handGetUrlByFileId = async (fileIdList) => {
      let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList.join(",") })?.then(
        (res) => res.data.map((item, index) => (fileIdList[index] ? item : undefined))
      )

      return result
    }
    //上传营业执照
    const onFinish = () => {
      let values: any = licenseForm.getFieldsValue()
      licenseForm
        .validateFields()
        .then(async (res) => {
          let params = {
            ...values,
            id: detailInfo?.id,
          }
          const result: any = await getBusinessAgenciesEdit(params)
          if (result.code == 200) {
            setLicenseOpen(false)
            licenseForm.resetFields()
            setLicenseImage([])
            init()
            message.success("上传营业执照成功")
          } else {
            message.error(result.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    //驳回原因
    const onFinishDown = () => {
      let values = form.getFieldsValue()
      form
        .validateFields()
        .then(async (res) => {
          const result: any = await getApprovalExamine({
            id: detailInfo?.id,
            status: 1,
            ...values,
          })
          if (result.code == 200) {
            setRejectionOpen(false)
            form.resetFields()
            init()
            message.success("驳回成功")
          } else {
            message.error(result.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    //完成税务登记
    const onTaxManFinish = () => {
      let values = taxationForm.getFieldsValue()
      taxationForm
        .validateFields()
        .then(async (res) => {
          let params = {
            ...values,
            invoiceClerk: [values.invoiceClerk],
            id: detailInfo?.id,
          }
          let TasksParams: any = {
            taxpayerName: detailInfo?.targetOrg.name,
            taxpayerNo: detailInfo?.targetOrg.idNo,
            taxFilingPeriod: values?.declarationCycle,
            taxClerk: values?.invoiceClerk,
            handelRegion: detailInfo?.handleRegion?.name,
          }
          const result: any = await getTaxRegistrationEdit(params)
          if (result.code == 200) {
            const res: any = await getTasksTaxpayer(TasksParams)
            setTaxationOpen(false)
            init()
            message.success("完成税务登记成功")
          } else {
            message.error(result.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    useEffect(() => {
      init()
      handGetPlatCharactersList()
    }, [])
    return (
      <DetailPageContainer>
        <DetailPageHeader
          title={
            <Space>
              <Button onClick={() => navigate(`/agencyBusiness/setup`)}>返回</Button>
              <span>个体户开办详情</span>
            </Space>
          }
          breadCrumb={[
            { label: "业务代办" },
            { label: "工商税务开办" },
            { label: "个体户开办申请" },
            { label: "个体户开办详情" },
          ]}
          rightContent={
            <Space size="large" direction="vertical">
              <div className="applicationDetails">
                {/* 审核通过状态 */}
                <Button
                  type="primary"
                  style={{
                    display:
                      detailInfo?.checkStatus == "CHECK_PASS" ||
                      detailInfo?.businessLicenseStatus == "HANDLED"
                        ? ""
                        : "none",
                  }}
                  onClick={async () => {
                    const downloadName = detailInfo?.legalName + "营业执照办理申请"
                    download(detailInfo?.id, downloadName).then((data: any) => {
                      init()
                    })
                  }}
                >
                  下载办理资料
                </Button>
                <Access accessible={access.op_agencyBusiness_setup_feature_detail_upload}>
                  <Button
                    type="primary"
                    style={{
                      display:
                        detailInfo?.checkStatus == "CHECK_PASS" &&
                        detailInfo?.businessLicenseStatus == "UNHANDLED"
                          ? ""
                          : "none",
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      setLicenseOpen(true)
                    }}
                  >
                    上传营业执照
                  </Button>
                </Access>
                <Access accessible={access.op_agencyBusiness_setup_feature_detail_done}>
                  <Button
                    type="primary"
                    className="applicationDetails-done"
                    style={{
                      display:
                        detailInfo?.businessLicenseStatus == "HANDLED" &&
                        detailInfo?.taxRegistrationStatus !== "REGISTERED"
                          ? ""
                          : "none",
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      setTaxationOpen(true)
                    }}
                  >
                    完成税务登记
                  </Button>
                </Access>

                {/* 未提交状态 */}
                <Access accessible={access.op_agencyBusiness_setup_feature_detail_edit}>
                  <Button
                    style={{
                      display: detailInfo?.checkStatus == "UN_COMMIT" ? "" : "none",
                      marginLeft: 10,
                    }}
                    onClick={() =>
                      navigate(`/agencyBusiness/setup/newApplication/${detailInfo?.id}`)
                    }
                  >
                    编辑
                  </Button>
                </Access>
                <Button
                  style={{
                    display:
                      detailInfo?.checkStatus == "UN_COMMIT" && turnDownData?.length > 0
                        ? ""
                        : "none",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  查看驳回记录
                </Button>
                <Button
                  type="primary"
                  style={{
                    display: detailInfo?.checkStatus == "UN_COMMIT" ? "" : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    let params = { ...detailInfo, doCode: 1 }
                    const result: any = await getOperAgenciessAdd(params)
                    if (result.code == 200) {
                      setSubmitOpen(false)
                      init()
                    }
                  }}
                >
                  提交申请
                </Button>
                {/* 已提交状态 和待审核 状态 */}
                {/* <div className="details-submitted"> */}
                <Button
                  type="primary"
                  style={{
                    display:
                      (detailInfo?.checkStatus == "COMMIT" ||
                        detailInfo?.checkStatus == "WAIT_CHECK") &&
                      detailInfo?.delegatedAgreement?.type == 1
                        ? ""
                        : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    const result: any = await getDelegatedAgreement({ id: detailInfo?.id })
                    if (result.code == 200) {
                      message.success("重发委托协议成功")
                    } else {
                      message.error(result.message)
                    }
                  }}
                >
                  重发委托协议短信
                </Button>
                <Button
                  style={{
                    display:
                      detailInfo?.checkStatus == "COMMIT" &&
                      detailInfo?.delegatedAgreement?.type == 1
                        ? ""
                        : "none",
                    marginLeft: 10,
                  }}
                  onClick={async (e: any) => {
                    const result: any = await getContractsSignUrl({
                      idNumber: detailInfo?.legalIdno,
                      contractNo: detailInfo?.contractNo,
                    })
                    if (result.code == 200) {
                      message.success("复制成功")
                      copy(result.data)
                    } else {
                      message.error(result.message || "复制失败")
                    }
                  }}
                >
                  复制委托协议链接
                </Button>
                <Button
                  type="primary"
                  style={{
                    display:
                      (detailInfo?.checkStatus == "COMMIT" ||
                        detailInfo?.checkStatus == "WAIT_CHECK") &&
                      detailInfo?.handleRegion?.flowConfig?.isNeedDual
                        ? ""
                        : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    const result: any = await getResendDual({ id: detailInfo?.id })
                    if (result.code == 200) {
                      message.success("重发双录视频短信成功")
                    } else {
                      message.error(result.message)
                    }
                  }}
                >
                  重发双录视频短信
                </Button>
                {/* <Button
                    style={{
                      display: detailInfo?.checkStatus == "COMMIT" ? "" : "none",
                      marginLeft: 10,
                    }}
                    onClick={async () => {
                      copy("asdasdasd")
                      message.success("复制成功")
                    }}
                  >
                    录制双录视频链接
                  </Button> */}
                <Button
                  type="primary"
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    const res = await sendMs(detailInfo?.id)
                    message.info(res?.message)
                  }}
                >
                  发送青易办签署通知
                </Button>
                <Button
                  type="primary"
                  style={{
                    display: detailInfo?.checkStatus == "WAIT_CHECK" ? "" : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    const result: any = await getApprovalExamine({
                      id: detailInfo?.id,
                      status: 0,
                    })
                    if (result.code == 200) {
                      message.success("审核通过")
                      init()
                    } else {
                      message.error(result.message)
                    }
                  }}
                >
                  审核通过
                </Button>
                <Button
                  danger
                  style={{
                    display: detailInfo?.checkStatus == "WAIT_CHECK" ? "" : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    setRejectionOpen(true)
                  }}
                >
                  审核驳回
                </Button>
                <Button
                  style={{
                    display: detailInfo?.checkStatus == "WAIT_CHECK" ? "" : "none",
                    marginLeft: 10,
                  }}
                  onClick={async () => {
                    navigate(`/agencyBusiness/ModifyInformation/${detailInfo.id}`)
                  }}
                >
                  修改申请信息
                </Button>
                {/* </div> */}
                {detailInfo?.businessLicenseStatus == "UNHANDLED" && (
                  <Popconfirm
                    title="确认撤销申请吗?"
                    okText="确定"
                    cancelText="取消"
                    onConfirm={async () => {
                      // 调用接口刷新页面
                      const res: any = await RevokeApplication(detailInfo)
                      if (res.code == 200) {
                        navigate(`/agencyBusiness/setup`)
                        message.success("已撤销")
                      }
                    }}
                  >
                    <Button
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      撤销申请
                    </Button>
                  </Popconfirm>
                )}
              </div>
              <Space size="large" style={{ textAlign: "right" }}>
                <div className={classWithPrefix("status-box")}>
                  <div className={classWithPrefix("status-label")}>审核状态</div>
                  <TagCustomStyle
                    noBGColor
                    size="large"
                    // @ts-ignore
                    // examineStatusEnum
                    {...examineStatusEnum[detailInfo?.checkStatus]}
                  />
                </div>
                <div className={classWithPrefix("status-box")}>
                  <div className={classWithPrefix("status-label")}>营业执照</div>
                  <TagCustomStyle
                    noBGColor
                    size="large"
                    // @ts-ignore
                    // businessLicenseStatus
                    {...businessLicenseStatusEnum[detailInfo?.businessLicenseStatus]}
                  />
                </div>
                <div className={classWithPrefix("status-box")}>
                  <div className={classWithPrefix("status-label")}>税务登记</div>
                  <TagCustomStyle
                    noBGColor
                    size="large"
                    // @ts-ignore
                    //taxRegistrationStatus
                    {...taxRegistrationStatusEnum[detailInfo?.taxRegistrationStatus]}
                  />
                </div>
              </Space>
            </Space>
          }
        >
          <Descriptions>
            {/* handleType */}
            <Descriptions.Item label="办理类型" style={{ paddingTop: 16 }}>
              个体户办理
            </Descriptions.Item>
          </Descriptions>
        </DetailPageHeader>
        <DetailPageContent>
          {/* 组织信息 */}
          {detailInfo?.businessLicenseStatus == "HANDLED" ? (
            <Organizational list={detailInfo?.targetOrg} />
          ) : null}
          {/* 名称申报 */}
          <NameDeclaration list={detailInfo} />
          {/* 基本信息 */}
          <DetailsInformation list={detailInfo} registrationList={registrationList} />
          {/*银行信息*/}
          <BankInfo list={detailInfo} />
          {/* 材料上传 */}
          <MaterialUploadDetails
            list={detailInfo}
            videoList={videoList}
            setFileImgData={setFileImgData}
            fileImgData={fileImgData}
            submitOpen={submitOpen}
          />
          {/* 操作日志 */}
          <OperationLog list={detailInfo} />
        </DetailPageContent>
        <Modal
          title="驳回记录"
          open={isModalOpen}
          footer={null}
          width={650}
          onCancel={() => {
            setIsModalOpen(false)
          }}
        >
          <Table
            dataSource={turnDownData}
            columns={TurnDownColumns}
            pagination={false}
            className="modal-details"
          />
          <Form.Item style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <Button
                className="check-modal-button"
                onClick={() => {
                  setIsModalOpen(false)
                }}
              >
                关闭
              </Button>
            </div>
          </Form.Item>
        </Modal>
        <Modal
          title="审核驳回原因"
          open={rejectionOpen}
          footer={null}
          width={600}
          onCancel={() => {
            form.resetFields()
            setRejectionOpen(false)
          }}
        >
          <Form form={form} className="details-form">
            <Form.Item
              label="请输入驳回原因"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "请输入驳回原因",
                },
                {
                  validator: async (rule: any, value: string) =>
                    inputDefaultRules(value, "驳回原因", 40), //特殊字符
                },
              ]}
            >
              <TextArea placeholder="请输入驳回原因" rows={4} style={{ width: 400 }}></TextArea>
            </Form.Item>
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    form.resetFields()
                    setRejectionOpen(false)
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={onFinishDown}>
                  确认驳回
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {/* 上传营业执照 */}
        <Modal
          title="上传营业执照"
          open={licenseOpen}
          footer={null}
          width={600}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={() => {
            licenseForm.resetFields()
            setLicenseImage([])
            setLicenseOpen(false)
          }}
        >
          <Form
            name="basic"
            form={licenseForm}
            className="detail-license"
            initialValues={{
              remember: true,
              applyNo: detailInfo?.applyNo,
              type: "INDIVIDUAL_BUSINESS",
              regionName: detailInfo?.handleRegion?.name,
              linkCustomerId: detailInfo?.linkCustomerId,
              taxRegionId: detailInfo?.handleRegion?.id,
            }}
            autoComplete="off"
          >
            <BusinessLicense
              originalOrgs={originalOrgs}
              form={licenseForm}
              licenseImage={licenseImage}
              setLicenseImage={setLicenseImage}
              list={detailInfo}
            />
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    licenseForm.resetFields()
                    setLicenseImage([])
                    setLicenseOpen(false)
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={onFinish}>
                  确认
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {/* 完成税务登记 */}
        <Modal
          title="完成税务登记"
          open={taxationOpen}
          footer={null}
          width={600}
          onCancel={() => {
            taxationForm.resetFields()
            setTaxationOpen(false)
          }}
        >
          <Form
            form={taxationForm}
            className="details-form"
            initialValues={{
              declarationCycle: "MONTH",
            }}
          >
            <Col>
              <Form.Item name="declarationCycle" label="申报周期" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="MONTH"> 按月申报 </Radio>
                  <Radio value="QUARTER"> 按季度申报 </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="invoiceClerk"
                label="办税人/开票员"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  placeholder="请选择"
                  style={{ width: 320 }}
                  onChange={(e) => {
                    console.log(e)
                  }}
                  options={getTaxMin}
                />
              </Form.Item>
            </Col>
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    taxationForm.resetFields()
                    setTaxationOpen(false)
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={onTaxManFinish}>
                  完成
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </DetailPageContainer>
    )
  }
)
ApplicationDetails.defaultProps = defaultProps

export default ApplicationDetails
